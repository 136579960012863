import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Layout, SliceZone } from 'components';

class Index extends Component {
  render() {
    const {
      data: { home },
    } = this.props;
    return (
      <Layout pageTitle={home.data.title.text} isHome>
        <SliceZone allSlices={home.data.body} />
      </Layout>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    home: prismicHome {
      data {
        title {
          text
        }
        body {
          ... on PrismicHomeBodyHero {
            sliceType: slice_type
            id
            primary {
              title: hero_title {
                text
              }
              content: hero_content {
                text
              }
              image: hero_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              additional: hero_additional {
                text
              }
            }
            items {
              buttonText: hero_button_text {
                text
              }
              buttonLink: hero_button_link {
                url
              }
            }
          }
          ... on PrismicHomeBodyIconGrid {
            sliceType: slice_type
            id
            primary {
              title: icon_section_title {
                text
              }
            }
            items {
              icon: icon_file {
                url
              }
              title: icon_title {
                text
              }
              content: icon_content {
                text
              }
            }
          }
          ... on PrismicHomeBodyCta {
            sliceType: slice_type
            id
            primary {
              title: cta_title {
                text
              }
              content: cta_content {
                text
              }
              hasSignUpForm: show_signup_form
            }
            items {
              buttonText: cta_button_text {
                text
              }
              buttonLink: cta_button_link {
                url
              }
            }
          }
          ... on PrismicHomeBodySlider {
            sliceType: slice_type
            id
            items {
              image: slider_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              title: slider_title {
                text
              }
              content: slider_content {
                text
              }
              linkTitleLeft: slider_link_title_left {
                text
              }
              linkUrlLeft: slide_link_url_left {
                url
              }
              linkTitleRight: slide_link_title_right {
                text
              }
              linkUrlRight: slider_link_url_right {
                url
              }
            }
          }
          ... on PrismicHomeBodyPricingTable {
            sliceType: slice_type
            id
            primary {
              title: pricing_title {
                text
              }
              content: pricing_content {
                text
              }
              freePlanName: free_plan_name {
                text
              }
              freePlanDescription: free_plan_description {
                text
              }
              freePlanFeatures: free_plan_features {
                html
              }
              mediumPlanName: medium_plan_name {
                text
              }
              mediumPlanDescription: medium_plan_description {
                text
              }
              mediumPlanFeatures: medium_plan_features {
                html
              }
              enterprisePlanName: enterprise_plan_name {
                text
              }
              enterprisePlanDescription: enterprise_plan_description {
                text
              }
              enterprisePlanFeatures: enterprise_plan_features {
                html
              }
            }
            items {
              userCount: user_count
              mediumPlanPrice: medium_plan_price {
                text
              }
              enterprisePlanPrice: enterprise_plan_price {
                text
              }
            }
          }
          ... on PrismicHomeBodyAccordion {
            sliceType: slice_type
            id
            primary {
              belowPricingTable: below_price
            }
            items {
              title: accordion_item_title {
                text
              }
              content: accordion_item_content {
                text
              }
            }
          }
          ... on PrismicHomeBodyTestimonials {
            sliceType: slice_type
            id
            primary {
              title: testimonials_title {
                text
              }
              content: testimonials_content {
                text
              }
            }
            items {
              avatar: testimonial_avatar {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1600, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              name: testimonial_name {
                text
              }
              company: testimonial_company {
                text
              }
              testimonial: testimonial_body {
                text
              }
            }
          }
        }
      }
    }
  }
`;
